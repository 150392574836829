import React, { useState } from "react";
import { danger, danger_sm, product } from "../../assets/img";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Close } from "@mui/icons-material";
import CheckingPricePoppup from "../EstimateComp/Poppup/CheckingPricePoppup";
import ImageUpload from "../EstimateComp/Poppup/ImageUpload";
import ImageView from "../EstimateComp/Poppup/ImageView";
import PresntationImage from "./PresntationImage";
import PresentationEditTable from "./PresentationEditTable";
import PresentationPricingTable from "./PresentationPricingTable";
import AddArtWorker from "./AddArtWorker";
import AddFilesPopup from "../Popup/AddFilesOverview";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { sub_menu } from "../../redux/api/DummyJson";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import HttpsRoundedIcon from "@mui/icons-material/HttpsRounded";
import SouthRoundedIcon from "@mui/icons-material/SouthRounded";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { Col } from "react-bootstrap";

const PresentationEditScreen = () => {
  const navigate = useNavigate();
  const [productName, setProductName] = useState("Brand Shirt");
  const [description, setDescription] = useState(
    "Material: Stainless Steel 304, Capacity: 500ml, Weight: 257gm, BPA Free, Screw Type Cop*Keeps liquid Hot & Cold: 12H-18Hr"
  );
  const [warnings, setWarnings] = useState([]);
  const [personalCom, setPersonalCom] = useState("");
  const [personalComs, setPersonalComs] = useState([]);
  const [addClient, setAddClient] = useState(false);
  const [AddFilesOverview, setAddFilesOverview] = useState(false);
  const [addArt, setAddArt] = useState(false);
  const [priceCharge, setPriceCharge] = useState([]);
  const [runCharge, setRunCharge] = useState([]);
  const [imageUpload, setImageUpload] = useState(false);
  const [ticketStatus, setTicketStatus] = useState(1);

  const [addLocation, setAddLocation] = useState([""]);
  const [location, setLocation] = useState([0]);
  const [addItemLoc, setAddItemLoc] = useState([""]);
  const [addFixedChargeDec, setAddFixedChargeDec] = useState([""]);
  const [addRunChargeDec, setAddRunChargeDec] = useState([""]);
  const [decoration, setDecoration] = useState([1, 2]);
  const [addColor, setAddColor] = useState([]);
  const [run, setRun] = useState({});
  const [fixed, setFixed] = useState({});
  const [decorationData, setDecorationData] = useState({
    0: {
      0: { text: "Imprint Cost", select: 1 },
      1: { text: "Setup Cost", select: 1 },
    },
  });

  const removeDecoration = (item, ind, ind2) => {
    let temp = [...decoration];

    let num = decoration[ind2];

    temp.splice(ind, 1);
    setDecoration(temp);

    if (Object.values(decorationData)?.length > 0) {
      let obj = decorationData[ind2];

      console.log("obj", obj);

      if (obj !== undefined) {
        delete obj[ind];

        let tem = Object.values(obj);

        if (tem?.length == 0) {
          delete decorationData[ind2];
        }

        setDecorationData((val) => ({ ...val }));
      }
    }
  };

  const chargeDecoration = (event, type, ind, ind2) => {
    let value = event.target.value;
    if (decorationData[ind2]) {
      let obj = { ...decorationData[ind2] };

      let tems = { ...obj[ind] };

      let tem = {
        ...obj,
        [ind]: { ...tems, [type]: value },
      };

      setDecorationData({ ...decorationData, [ind2]: tem });
    } else {
      let obj = { ...decorationData[ind2] };

      let tems = { ...obj[ind] };

      let tem = {
        ...obj,
        [ind]: {
          ...tems,
          [type]: value,
        },
      };

      setDecorationData({ ...decorationData, [ind2]: tem });
    }
  };

  const addWarn = () => {
    let temp = [...warnings];

    temp.push({ warn: "" });
    setWarnings(temp);
  };

  const removeWarn = (e, ind) => {
    let temp = [...warnings];
    temp.splice(ind, 1);
    setWarnings(temp);
  };

  const handleChangeWarn = (e, index) => {
    let temp = [...warnings];
    const name = e.target.name;
    const value = e.target.value;
    temp[index][name] = value;
    setWarnings(temp);
  };

  const addPost = () => {
    if (personalCom?.length > 0) {
      let temp = [...personalComs];

      temp.push({
        name: "Roger",
        command: personalCom,
        date: moment(new Date()).format("YYYY-MM-DD"),
      });

      setPersonalCom("");
      setPersonalComs(temp.reverse());
    }
  };

  const removePost = (item, index) => {
    let temp = [...personalComs];
    temp.splice(index, 1);
    setPersonalComs(temp);
  };

  const artWork = () => {
    toggleShowPopup();
  };

  const toggleShowPopup = () => {
    setAddClient(!addClient);
  };

  const toggleShowPopups = () => {
    setAddFilesOverview(!AddFilesOverview);
  };

  const locationRemove = (num) => {
    let temp = [...location];
    temp.splice(num, 1);
    setLocation(temp);
  };

  const removeAddItemClick = (e, ind, ind2) => {
    let temp = [...addItemLoc];
    temp.splice(ind2[ind], 1);
    setAddItemLoc(temp);
  };

  const addAddLocClick2 = () => {
    let temp = [...addLocation];
    temp.push({ warn: "" });
    setAddLocation(temp);
  };

  const locationAdd = (num, type) => {
    let temp = [...location];
    temp.push(num);

    for (let i = 0; i < temp?.length; i++) {
      setDecorationData((val) => ({
        ...val,
        [i + 1]: {
          0: { text: "Imprint Cost", select: 1 },
          1: { text: "Setup Cost", select: 1 },
        },
      }));
    }

    if (type == "copy") {
      // console.log("ss");
      let obj = { ...decorationData };
    }
    setLocation(temp);
  };

  const [dropdown, setDropDown] = useState(null);

  const poppupImageToggle = () => {
    setImageUpload(!imageUpload);
  };

  return (
    <div className="dashRightView p-5">
      {imageUpload && (
        <AddArtWorker
          toggleShowPopup={poppupImageToggle}
          setAddArt={setAddArt}
          toggleShowPopups={toggleShowPopups}
        />
      )}
      {/* {checkPricePoppup && <CheckingPricePoppup poppupAddressToggle={poppupAddressToggle} />}
            {imageUpload && <ImageUpload poppupImageToggle={poppupImageToggle} />}
            {imageViewPoppup && <ImageView poppupImageView={poppupImageView} />} */}
      <div className="w-100 d-flex ac-jb px-4">
        <div>
          <p className="f1 fs-15 fs-xs-16 fs-sm-18 fs-md-19 fs-lg-21 fs-xl-22 fs-xxl-23">
            Edit Item
          </p>
        </div>
        <div className="position-fixed close_position z-3">
          <button
            onClick={() => navigate("/presentation")}
            class="cust-btn addbtn mt-sm-3 ms-1 bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
          >
            Close
          </button>
        </div>
      </div>
      <div className="d-flex as-jb w-100 mt-5 edit_screen_cont res-flex_img gap-5">
        <div className="d-flex flex-wrap w-100 w-md-70 ac-jb res-flex_im flex-m-r">
          <div className="d-flex w-100 gap-3 ac-jb flex-m-r">
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Product Name
              </p>
              <div className="position-relative">
                <input
                  placeholder="Required Field"
                  className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
              </div>
            </div>
            <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
              <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Supplier
              </p>
              <div className="position-relative">
                <input
                  value={"Sample Supplier"}
                  placeholder="Required Field"
                  disabled
                  type={"text"}
                  className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setDropDown(1);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {dropdown == 1 && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setDropDown(null)}
                  />
                )}
                <div
                  className={`${
                    dropdown == 1 && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  {sub_menu?.map((item) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setDropDown(null);
                          }}
                        >
                          {item?.list}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="w-xl-30 w-lg-30 w-md-100 w-sm-100 w-xs-100">
              <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                SKU
              </p>
              <div className="position-relative">
                <p className="w-100 rounded-3 mt-2 py-2 f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust">
                  301WH1026
                </p>
              </div>
            </div>
            <div className="w-xl-30 w-lg-30 w-md-100 w-sm-100 w-xs-100">
              <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                Decoration Methods
              </p>

              <div className="position-relative">
                <input
                  value={"Unspecified"}
                  placeholder="Required Field"
                  disabled
                  type={"text"}
                  className="editBtn cp w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                />
                <button
                  className="drop_down cust-btn"
                  onClick={() => {
                    setDropDown(2);
                  }}
                >
                  <KeyboardArrowDownIcon />
                </button>
                {dropdown == 2 && (
                  <div
                    className="invisible-cont2 z-0"
                    onClick={() => setDropDown(null)}
                  />
                )}
                <div
                  className={`${
                    dropdown == 2 && "submenu_1"
                  } submenu_cont_1 overflow-scroll z-3`}
                >
                  {sub_menu?.map((item) => {
                    return (
                      <div className="d-flex hover-cust">
                        <button
                          className="px-2 cust-btn text-start py-1 w-100"
                          onClick={() => {
                            setDropDown(null);
                          }}
                        >
                          {item?.list}
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap w-100 w-md-70 ac-jb res-flex_im flex-m-r">
            <div className="d-flex w-100 gap-3 ac-jb flex-m-r">
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Production Lead Time
                </p>
                <div className="position-relative">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    // type={'time'}
                  />
                </div>
              </div>
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Sample Lead Time
                </p>
                <div className="position-relative">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    // type={'time'}
                  />
                </div>
              </div>
              <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
                <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                  Product Note
                </p>
                <div className="position-relative">
                  <textarea
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-100">
            <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Description
            </p>
            <div className="position-relative">
              <textarea
                placeholder="Required Field"
                className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
              />
            </div>
          </div>
          {warnings?.map((item, ind) => (
            <div className="w-100 d-flex align-items-start  align-items-md-center gap-0 gap-md-3 flex-column flex-md-row">
              <button className="black d-flex gap-1 ac-jc cust-btn w-auto text-nowrap f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
                <img src={danger} className="danger_icon" />
                <p className="f2 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  WARNING:
                </p>
              </button>
              <div className="w-100 d-flex ac-jc gap-2">
                <div className="position-relative w-100">
                  <input
                    placeholder="Required Field"
                    className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                    onChange={(e) => handleChangeWarn(e, ind)}
                  />
                </div>
                <button
                  className="cust-btn"
                  onClick={() => removeWarn(item, ind)}
                >
                  <Close className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16" />
                </button>
              </div>
            </div>
          ))}
          <div className="d-flex ac-js gap-2 mt-3 flex-column flex-sm-row w-100 mt-4">
            <button
              class="text-nowrap cust-btn addbtn ms-1 bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
              onClick={() => addWarn()}
            >
              Add Warning
            </button>
            <p className="fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-13 fs-xxl-14">
              Please check supplier website for any warnings now shown
            </p>
          </div>
          <div className="d-flex mt-5 ac-jb flex-column flex-md-row w-100">
            <div className="d-flex mt-3 ac-jc gap-2 flex-column flex-sm-row">
              <button
                onClick={() => navigate("/product-library-details")}
                className="text-nowrap cust-btn addbtn bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
              >
                View Product Page
              </button>
              <button
                onClick={() =>
                  navigate("/product-library-details", {
                    state: { type: "price" },
                  })
                }
                className="text-nowrap cust-btn addbtn bg-primar text-white f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-5 rounded"
              >
                Check Pricing
              </button>
            </div>
            <div className="mt-md-4 mt-3">
              <h5 className="f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25 w-100 text-dark">
                Currency :
                <span className="primary f4 fs-xs-20 fs-sm-21 fs-md-22 fs-lg-23 fs-xl-24 fs-xxl-25">
                  INR
                </span>
              </h5>
            </div>
          </div>
          <div className="overflow-scroll w-100">
            <PresentationEditTable
              runCharge={runCharge}
              setRunCharge={setRunCharge}
            />
          </div>
        </div>
        <PresntationImage
          artWork={artWork}
          runCharge={runCharge}
          setRunCharge={setRunCharge}
          setPriceCharge={setPriceCharge}
          priceCharge={priceCharge}
        />
        {addClient && (
          <AddArtWorker
            toggleShowPopup={toggleShowPopup}
            setAddArt={setAddArt}
            toggleShowPopups={toggleShowPopups}
          />
        )}
        {AddFilesOverview && (
          <AddFilesPopup
            toggleShowPopup={toggleShowPopups}
            popupType={"addClient"}
          />
        )}
      </div>
      <div>
        <div
          className={`
              toggle_box1 d-flex ac-jc  rounded-5 w-20`}
        >
          <button className="cust-btn f3 text-light py-2 py-sm-3  px-1 px-sm-3 d-flex ac-jc text-nowrap f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
            Supplier Decorator
          </button>
        </div>
        <div className="position-relative">
          <div className="position-relative mt-3">
            <button
              onClick={() => {
                setDropDown(8);
              }}
              className="pointerView cust-btn px-4 py-2 tranc cp"
            >
              <p className="primary f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                + Decoration Location
              </p>
            </button>
            {dropdown === 8 && (
              <div
                className="invisible-cont2 z-0"
                onClick={() => setDropDown(undefined)}
              />
            )}
            <div
              className={`${
                dropdown === 8 && "submenu_1 h-auto"
              } submenu_cont_1 overflow-scroll z-3`}
              style={{ width: "300px" }}
            >
              <button className="d-flex cust-btn w-100 as-jb hover-cust ac-jb">
                <button
                  onClick={() => {
                    setDropDown(undefined);
                    addAddLocClick2();
                    locationAdd(1);
                  }}
                  className="px-2 cust-btn text-start py-1 w-100 "
                >
                  Add new decoration location
                </button>
              </button>
            </div>
          </div>
        </div>
        {location?.map((item2, ind2) => {
          return (
            <div className="w-100 overflow-scroll mt-5 edit_page_cust">
              <table className="w-100">
                <div className="d-flex hide_res">
                  {location?.length > 1 && (
                    <div
                      className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                      style={{ width: "20px" }}
                      onClick={() => {
                        locationRemove(ind2);
                      }}
                    >
                      <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                    </div>
                  )}
                  <p className="primary f3 ms-5 pt-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                    Location {ind2 + 1}
                  </p>
                </div>
                {addItemLoc?.map((item, ind) => {
                  return (
                    <tr>
                      <div className="py-3 hide_res">
                        <td>
                          <div className="d-flex w-100">
                            <div
                              className="mx-2 d-flex ac-jc position-relative hide_res2 invisible cp"
                              style={{ width: "20px" }}
                              onClick={() => {
                                removeAddItemClick(item, ind, ind2);
                                // ind === 0 && removeAddLocClick2(item2, ind2);
                              }}
                            >
                              <Close className="f1 fs-xs-13 fs-sm-13 fs-md-13 fs-lg-14 fs-xl-17 fs-xxl-18 text-center gray" />
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "415px" }}
                            >
                              <div className="w-100">
                                <input
                                  placeholder="Required Field"
                                  className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                                  value={"Top"}
                                />
                              </div>
                            </div>
                            <div className="mx-2 d-flex ac-jc position-relative">
                              <button
                                onClick={poppupImageToggle}
                                className=" bg-transparent doted_border d-flex ac-js "
                              >
                                <div className="">
                                  <img
                                    src={product}
                                    className="object-fit-contain img_style"
                                  />
                                </div>
                              </button>
                            </div>
                            <div
                              className="mx-2 d-flex ac-jc position-relative"
                              style={{ width: "200px" }}
                            >
                              <div className="d-flex ac-js gap-2 ">
                                <img src={danger_sm} className="danger_sm" />
                                <p className="fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                  Proof Required
                                </p>
                              </div>
                            </div>
                          </div>
                        </td>
                      </div>
                    </tr>
                  );
                })}
                <tr>
                  <div className="py-3 hide_res">
                    <td>
                      <div className="w-100 d-flex ac-js">
                        <Col sm={12} md={6}>
                          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                            <div className="w-100 mt-3">
                              <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                                Decorative Method
                              </p>
                              <div className="w-100 ms-3">
                                <select
                                  placeholder=""
                                  className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                                  name=""
                                  id=""
                                >
                                  <option>3D</option>
                                  <option>Fabric</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={6}>
                          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                            <div className="w-100 mt-3">
                              <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                                {/* Imprint Type: */}
                                Proof Required
                              </p>
                              <div className="w-100 ms-3">
                                <select
                                  placeholder=""
                                  className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                                  name=""
                                  id=""
                                >
                                  <option>Email Proof</option>
                                  <option>Digital Photo</option>
                                  <option>Pre Production</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={6}>
                          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                            <div className="w-100 mt-3">
                              <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                                Logo Name
                              </p>
                              <div className="w-100 ms-3">
                                <textarea
                                  placeholder="Required Field"
                                  className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={6}>
                          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                            <div className="w-100 mt-3">
                              <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                                UOM
                              </p>
                              <div className="w-100 ac-js d-flex gap-3 ms-3">
                                <div className="ps-2 bg-transparent ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 ">
                                  <button
                                    className="d-flex gap-3  ac-js cust-btn"
                                    onClick={() => setTicketStatus(1)}
                                  >
                                    <div className="radio_btn d-flex ac-jc">
                                      <div
                                        className={`${
                                          ticketStatus === 1
                                            ? "sm_radio"
                                            : "sm_radio1"
                                        }`}
                                      />
                                    </div>
                                    <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      Inches
                                    </p>
                                  </button>
                                </div>
                                <div className="ps-2 bg-transparent ps-2 d-flex ps-2 gap-3 ac-js rounded-4 hover_Ef py-2 cp my-1 ">
                                  <button
                                    onClick={() => setTicketStatus(2)}
                                    className="d-flex gap-3  ac-js cust-btn"
                                  >
                                    <div className="radio_btn d-flex ac-jc">
                                      <div
                                        className={`${
                                          ticketStatus === 2
                                            ? "sm_radio"
                                            : "sm_radio1"
                                        }`}
                                      />
                                    </div>
                                    <p className="primary f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      Stitch
                                    </p>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </td>
                  </div>
                  <div className="py-3 hide_res">
                    <td>
                      <div className="w-100 d-flex ac-js">
                        <Col sm={12} md={6}>
                          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                            <div className="w-100 mt-3">
                              <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                                Logo Size
                              </p>
                              <div className="w-100 ms-3 d-flex gap-2">
                                <input
                                  placeholder="Length"
                                  className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                />
                                <input
                                  placeholder="Breadth"
                                  className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={6}>
                          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                            <div className="w-100 mt-3">
                              <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                                Logo Color
                              </p>
                              <div className="w-100 ms-3">
                                <textarea
                                  placeholder="Required Field"
                                  className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={6}>
                          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                            <div className="w-100 mt-3">
                              <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                                Repeat Logo?
                              </p>
                              <div className="w-100 ms-3">
                                <select
                                  placeholder=""
                                  className="w-100 editBtnSelect f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 black rounded-3 mt-2"
                                  name=""
                                  id=""
                                >
                                  <option>Yes</option>
                                  <option>No</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12} md={6}>
                          <div className="w-xxl-85 w-xl-85 w-lg-85 w-md-100">
                            <div className="w-100 mt-3">
                              <p className="w-100 mx-3 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary1">
                                Supplier Notes
                              </p>
                              <div className="w-100 ms-3">
                                <textarea
                                  placeholder="Required Field"
                                  className="p-cust editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </td>
                  </div>
                </tr>
              </table>
            </div>
          );
        })}
      </div>

      <div className="d-flex w-100 gap-3 ac-jb flex-m-r my-3 ">
        <div className="w-xl-100 w-lg-48 w-md-100 w-sm-100 w-xs-100 mt-3">
          <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Personal Comment:
          </p>
          <div className="position-relative">
            <textarea
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
            />
            <button
              style={{
                position: "absolute",
                top: -40,
                zIndex: 100,
                right: 0,
              }}
              class="cust-btn addbtn ms-1 mt-2 bg-primar text-white f2 fs-10 fs-xs-9 fs-sm-9 fs-md-9 fs-lg-9 fs-xl-10 fs-xxl-10 py-2 py-md-2 px-5 rounded"
            >
              Post
            </button>
          </div>
        </div>
        <div className="w-xl-100 w-lg-48 w-md-100 w-sm-100 w-xs-100 mt-3">
          <p className="primary1 f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
            Private Notes:
          </p>
          <div className="position-relative">
            <textarea
              placeholder="Required Field"
              className="editBtn w-100 rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresentationEditScreen;
